// 我的交易
<template>
  <el-card style=" min-height: calc(100vh - 134px)">
    <div class="trade-tab">
      <span class="mr-30 cur" @click="$router.push({ path: '/releasetrans' })">发布交易</span>
      <span class="cur" @click="$router.push({ path: '/releasepur' })">发布求购</span>
    </div>
    <div class="additem-title f14 mt-30">
      <span class="mr-30" :class="{ active: type == 1 }" @click="tab(1)">交易列表</span>
      <span :class="{ active: type == 2 }" @click="tab(2)">求购列表</span>
    </div>
    <div v-show="type == 1">
      <div class="mt-20 patent">
        <span @click="patent(1)" :class="{ active: patentindex == 1 }">专利</span>
        <span @click="patent(2)" :class="{ active: patentindex == 2 }">商标</span>
      </div>
      <el-table
        :data="tableData1"
        border
        :header-cell-style="{
          color: '#222530',
          fontSize: '14px',
          backgroundColor: '#eaf1ff',
          borderColor: '#ccc',
        }"
        style="font-size: 10px; margin-top: 30px"
      >
        <el-table-column label="交易类型" align="center">
          <template>{{ patentindex == 1 ? "专利" : "商标" }}</template>
        </el-table-column>
        <el-table-column label="交易状态" align="center">
          <template slot-scope="scope">
            {{
            scope.row.status == 0? "待审核": scope.row.status == 1?'已发布':scope.row.status == 2?'已出售':scope.row.status == 3?'审核未通过': ""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="交易商品" align="center"></el-table-column>
        <el-table-column prop="price" label="金额(元)" align="center"></el-table-column>
        <el-table-column prop="create_time" label="发布时间" align="center"></el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-tag effect="plain" class="mr-10 cur" @click="ja(scope.row)">降价</el-tag>
            <el-tag effect="plain" @click="bj(1, scope.row)" class="cur">编辑</el-tag>
            <!-- <el-tag class="cur" effect="plain" @click="sc(1, scope.row)"
              >删除</el-tag
            >-->
          </template>
        </el-table-column>
      </el-table>
      <div v-show="patentindex == 1 && total1>0" class="t-c mt-20">
        <el-pagination
          @current-change="changePage1"
          background
          layout="prev, pager, next"
          :total="total1"
          :page-size="pagesize1"
        ></el-pagination>
      </div>
      <div v-show="patentindex == 2 && total2>0"  class="t-c mt-20">
        <el-pagination
          @current-change="changePage2"
          background
          layout="prev, pager, next"
          :total="total2"
          :page-size="pagesize2"
        ></el-pagination>
      </div>
    </div>
    <div v-show="type == 2">
      <el-table
        :data="tableData3"
        border
        :header-cell-style="{
        color: '#222530',
        fontSize: '14px',
        backgroundColor: '#eaf1ff',
        borderColor: '#ccc',
      }"
        style="font-size: 10px; margin-top: 30px"
      >
        <el-table-column label="求购类型" align="center">
          <template slot-scope="scope">
            {{
            scope.row.type == 1 ? "专利" : "商标"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="product_type" label="求购商品" align="center"></el-table-column>
        <el-table-column prop="product_status" label="求购产品状态" align="center"></el-table-column>
        <el-table-column prop="linkman" label="求购人" align="center"></el-table-column>
        <el-table-column prop="phone" label="求购人手机号" align="center"></el-table-column>
        <el-table-column label="求购状态" align="center">
          <template slot-scope="scope">
            {{
            scope.row.status == 1
            ? "求购中"
            : scope.row.status == 2
            ? "已处理"
            : "失效"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="发布时间" align="center"></el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <!-- <el-tag class="cur" effect="plain" @click="bj(2, scope.row)"
            >编辑</el-tag
            >-->
            <el-tag class="cur" effect="plain" @click="sc(3, scope.row)">删除</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="t-c  mt-30" v-show="total3>0">
        <el-pagination
          @current-change="changePage3"
          background
          layout="prev, pager, next"
          :total="total3"
          :page-size="pagesize3"
        ></el-pagination>
      </div>
    </div>
    <el-dialog title="价格调整" :visible.sync="dialogVisible" width="600px">
      <div class="reduction flex">
        <div class="top mr-30">
           <el-image
           style="width: 94px;height: 120px;"
      :src="jimg"
      fit="contain"></el-image>
        </div>
        <div style="flex: 1" class="flex-al">
          <div class="flex-j-b" style="width: 100%">
            <div>
              <span class="f18 bd color-0">现价：￥{{ xprice }}</span>
              <span></span>
            </div>
            <div>
              <span class="f14 bd color-0">降价至：</span>
              <input class="jinput" v-model="jprice">
            </div>
          </div>
          <!-- <div class="discount flex-j-b">
            <div class="discount-item active">
              <div class="discount-item-top">打9折</div>
              <div class="discount-item-bot">￥4500.00</div>
            </div>
            <div class="discount-item">
              <div class="discount-item-top">打9折</div>
              <div class="discount-item-bot">￥4500.00</div>
            </div>
            <div class="discount-item">
              <div class="discount-item-top">打9折</div>
              <div class="discount-item-bot">￥4500.00</div>
            </div>
            <div class="discount-item">
              <div class="discount-item-top">打9折</div>
              <div class="discount-item-bot">￥4500.00</div>
            </div>
          </div>-->
        </div>
      </div>
      <div class="t-c mt-30 btn">
        <el-button class="bg-b" @click="upPrice">确认提交</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      patentindex: 1,
      pagesize1: 6,
      page1: 1,
      total1: 0,
      pagesize2: 6,
      page2: 1,
      total2: 0,
      pagesize3: 6,
      page3: 1,
      total3: 0,
      tableData1: [],
      tableData3: [],
      type: "1",
      jimg: "",
      jprice: "",
      xprice: "",
      id: ""
    };
  },
  created() {
    this.$route.query.type||''
    if(this.$route.query.type){
      this.type=2
    }
    this.getlist();
  },
  methods: {
    releasePur() {},
    releaseTrans() {},
    tab(type) {
      this.type = type;
      this.getlist();
    },
    getlist() {
      if (this.type == 1) {
        let url =
          this.patentindex == 1
            ? `/client/transaction/getListByUser?page=${this.page1}&limit=${
                this.pagesize1
              }`
            : `/client/transaction/getTrademarkListByUser?page=${
                this.page2
              }&limit=${this.pagesize2}`;
        this.$request.get(url).then(res => {
          this.tableData1 = res.data.data;
          if (this.patentindex == 1) {
            this.total1 = res.data.total;
          }
          if (this.patentindex == 2) {
            this.total2 = res.data.total;
          }
        });
      }
      if (this.type == 2) {
        this.$request
          .post(`/client/transaction/buyListData`, {
            page: this.page3,
            limit: this.pagesize3
          })
          .then(res => {
            if (res.data.code == 200) {
              this.tableData3 = res.data.data;
              this.total3 = res.data.total;
            }
          });
      }
    },
    bj(n, row) {
      let id = row.id;
      if (n == 1) {
        this.$router.push({
          path: "/releasetrans",
          query: { type: this.patentindex, id }
        });
      }
      if (n == 2) {
        this.$router.push({ path: "/releasepur", query: { ...row } });
      }
    },
    ja(row) {
      this.dialogVisible = true;
      this.jimg = row.design_img;
      this.xprice = row.price;
      this.id = row.id;
    },
    upPrice() {
      var priceReg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
      let price = this.jprice;
      if (price === "") {
        this.$message.error("请输入出售价格");
        return;
      }
      if (!priceReg.test(price)) {
        this.$message.error("请输入正确的产品价格:整数或者保留两位小数");
        return;
      }
      let url =
        this.patentindex == 1
          ? "/client/transaction/editPatentPrice"
          : "/client/transaction/editTrademarkPrice";
      this.$request.post(url, { price, id: this.id }).then(res => {
        if (res.data.code == 200) {
          this.dialogVisible = false;
          this.getlist();
        }
      });
    },
    sc(n, row) {
      let order_no = row.order_no;
      // if (n == 1) {
      //   this.$request
      //     .post("/client/transaction/del", { order_no })
      //     .then((res) => {
      //       if(res.data.code){
      //           this.getlist();
      //       }

      //     });
      // }
      // if (n == 2) {
      //   this.$request
      //     .post("/client/transaction/buyOrdersDel", { order_no })
      //     .then((res) => {
      //       if (res.data.code == 200) {
      //         this.getlist();
      //       }
      //     });
      // }
      if (n == 3) {
        this.$request
          .post("/client/transaction/buyOrdersDel", { order_no })
          .then(res => {
            if (res.data.code == 200) {
              this.getlist();
            }
          });
      }
    },
    patent(indexs) {
      this.patentindex = indexs;
      this.getlist();
    },
    changePage1(index) {
      this.page1 = index;
      this.getlist();
    },
    changePage2(index) {
      this.page2 = index;
      this.getlist();
    },
    changePage3(index) {
      this.page3 = index;
      this.getlist();
    }
  }
};
</script>

<style scoped>
.additem-title {
  border-bottom: 1px solid #e6e6e6; 
}
.additem-title span {
  font-weight: 700;
  display: inline-block;
  padding: 10px;
  color: #bbb;
  cursor: pointer;
}
.additem-title span.active {
  border-bottom: 3px solid #2962ff;
  color: #000;
}
.trade-tab span {
  display: inline-block;
  width: 96px;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
}
.trade-tab span:first-child {
  background-color: #2962ff;
}
.trade-tab span:last-child {
  background-color: #ff8547;
}
.reduction .top {
  width: 94px;
  height: 120px;
  border:1px solid #eee;
  border-radius: 6px;
}
body >>> .el-dialog {
  width: 600px;
}
.reduction input {
  width: 150px;
  height: 30px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}
.reduction img {
  width: 100%;
  height: 100%;
}
.discount {
  margin-top: 35px;
}
.discount-item {
  /* border: 1px solid #555a68;
border-radius: 7px; */
}
.discount-item-top {
  width: 92px;
  height: 24px;
  background: #555a68;
  color: #fff;
  border: 1px solid #555a68;
  border-radius: 7px 7px 0 0;
  line-height: 24px;
  text-align: center;
}
.discount-item-bot {
  width: 92px;
  height: 24px;
  border: 1px solid #555a68;
  border-radius: 0 0 7px 7px;
  line-height: 24px;
  text-align: center;
}
.discount-item.active .discount-item-bot {
  border: 1px solid #ff8547;
  color: #ff8547;
}
.discount-item.active .discount-item-top {
  border: 1px solid #ff8547;
  background: #ff8547;
}
/* /* >>> .el-table td, .el-table th.is-leaf {
    border-bottom: 14px solid #EBEEF5;
} */
/* >>> .el-table .el-table__row:last-child  td {
    border-bottom: 1px solid #fff;
}  */
body >>> .el-table::before {
  left: 0;
  bottom: 0;
  width: 0;
  height: 0px;
}
.btn >>> .el-button:focus,
.el-button:hover {
  color: #fff;
  border-color: #2962ff;
  background-color: #2962ff;
}
.jinput {
  outline: none;
}
.jinput:focus {
  border: 1px solid #2962ff;
}
.patent span {
  display: inline-block;
  padding: 4px 30px;
  color: #686868;
  font-size: 14px;
  border: 1px solid #bbb;
  border-radius: 10px;
  margin-right: 30px;
  cursor: pointer;
}
.patent span.active {
  color: #fff;
  background-color: #2962ff;
  border-color: #2962ff;
}
.el-card >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2962ff;
  color: #fff;
}
</style>
